<template>
    <div class="description">
        {{ text }}
    </div>
</template>
<script>
export default {
    name: "Description",
    props: {
        text: String
    }
}
</script>
<style lang="scss" scoped>
    .description {
        height: 65px;
        line-height: 65px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
</style>