<template>
    <div
        class="title"
        :style="{
            'background-color': bcColor,
            'color': color
        }"
        :class="textAlign ? 'text-align-center' : 'text-align-left'"
        >
        <span class="content">{{ text }}</span>
    </div>
</template>
<script>
export default {
    name: "Title",
    props: {
        text: String,
        color: String,
        bcColor: String,
        textAlign: {
            type: Boolean,
            default: function () {
                return true
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .title {
        width: 100%;
        height: 44px;
        line-height: 44px;
        box-sizing: border-box;
        padding: 0 16px;
        border-bottom: 1px solid #EDEFF2;
        background-color: #ffffff;
    }
    .text-align-center {
        text-align: center;

    }

    .text-align-left {
        text-align: left;

        .content {
            display: inline-block;
            line-height: 36px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2B2F33;
            border-bottom: 2px solid #3C7FFF;
        }
    }

</style>
