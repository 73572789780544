<template>
    <!-- PreviewArea 预览区 -->
    <div
        class="preview-area"
        :class="terminalStatus ? 'pc-terminal' : 'mobile-terminal'"
    >
        <div
            class="terminal"
            :class="terminalStatus ? 'pc-status' : 'mobile-status'"
        >
            <img
                class="pc-img"
                :src="pcImg"
                alt="pc"
                @click="pcChange"
            />
            <img
                class="mobile-img"
                :src="mobileImg"
                alt="mobile"
                @click="mobileChange"
            />
        </div>
        <div
            ref="areaWrapper"
            class="area-wrapper"
            :class="terminalStatus ? 'pc-area' : 'mobile-area'"
        >
            <el-form
                :size="formConf.size"
                :label-position="formConf.labelPosition"
                :disabled="formConf.disabled"
                :label-width="formConf.labelWidth + 'px'"
                :style="{width: '100%', height: '100%'}"
            >
                <draggable
                    class="drawing-board"
                    :list="list"
                    :animation="100"
                    group="componentsGroup"
                    draggable=".drawing-item"
                >
                    <template v-for="(element, index) in list">
                        <design-item
                            :key="index"
                            v-if="element.hideStatus"
                            :model="element"
                            :activeItem="activeItem"
                            @rowItemRollBack="handlerRollBack"
                            @onActiveItemChange="handlerActiveItemChange"
                            @copyItem="handlerItemCopy"
                            @deleteItem="handlerItemDelete"
                            @handlerKitAbstract="handlerKitAbstract"
                        >
                        </design-item>
                    </template>
                </draggable>
            </el-form>
        </div>
    </div>
</template>
<script>
// 拖拽组件
import draggable from "vuedraggable";
import formConf from "@/assets/data/formDesign/formConf.js";
import { getSimpleId, isLayout } from "@/libs/utils.js";
import DesignItem from "./DesignItem/index.vue";
export default {
    name: "PreviewArea",
    components: {
        draggable,
        DesignItem,
    },
    props: {
        list: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            formConf,
            lastActiveItem: {},
            activeItem: {},
            terminalStatus: true, // 终端状态
            pcSta: "pc", // 默认显示状态 pc
            mobileSta: "mobile_",
        };
    },
    computed: {
        pcImg() {
            return require(`@/assets/images/${this.pcSta}.png`);
        },
        mobileImg() {
            return require(`@/assets/images/${this.mobileSta}.png`);
        },
    },
    methods: {
        // pc端 移动端 切换显示界面
        // pcChange 显示PC端
        // mobileChange 显示移动端
        pcChange() {
            this.pcSta = "pc";
            this.mobileSta = "mobile_";
            this.terminalStatus = true;
        },
        mobileChange() {
            this.pcSta = "pc_";
            this.mobileSta = "mobile";
            this.terminalStatus = false;
        },
        handlerRollBack(rowItem, oldIndex) {
            //还原
            this.list.splice(oldIndex, 0, rowItem);
        },
        handlerActiveItemChange(obj) {
            console.log("chufal", obj);
            this.lastActiveItem = this.activeItem;
            this.activeItem = obj;

            this.$emit("handlerActiveItemChange", obj);
        },
        handlerItemCopy(origin, parent) {
            if (isLayout(origin)) {
                //布局组件，需要复制布局组件以及下面的组件
                const clone = JSON.parse(JSON.stringify(origin));
                const uId = "row_" + getSimpleId();
                clone.id = uId;
                clone._id = uId;
                clone.columns.map((column) => {
                    let itemList = [];
                    column.list.map((item, i) => {
                        const cloneitem = JSON.parse(JSON.stringify(item));
                        const uId = "fd_" + getSimpleId();
                        cloneitem.id = uId;
                        cloneitem._id = uId;
                        itemList.push(cloneitem);
                    });
                    column.list = [];
                    column.list = itemList;
                });
                this.list.push(clone);
                this.handlerActiveItemChange(clone);
            } else {
                //如果是普通组件，需要判断他是否再布局组件下。
                if (parent) {
                    parent.columns.map((column) => {
                        if (column.list.some((item) => item.id === origin.id)) {
                            const clone = JSON.parse(JSON.stringify(origin));
                            const uId = "fd_" + getSimpleId();
                            clone.id = uId;
                            clone._id = uId;
                            column.list.push(clone);
                            this.handlerActiveItemChange(clone);
                        }
                    });
                } else {
                    const clone = JSON.parse(JSON.stringify(origin));
                    const uId = "fd_" + getSimpleId();
                    clone.id = uId;
                    clone._id = uId;
                    this.list.push(clone);
                    this.handlerActiveItemChange(clone);
                }
            }
            const scrollToBottom = () => {
                const scrollContainer = this.$refs.areaWrapper;
                const contentHeight = scrollContainer.scrollHeight;
                const scrollHeight = contentHeight - scrollContainer.clientHeight;
                scrollContainer.scrollTo({
                    top: scrollHeight,
                    behavior: 'smooth', // 平滑滚动行为
                });
            }
            this.$nextTick(() => {
                scrollToBottom()
            })
        },
        handlerItemDelete(origin, parent) {
            console.log(origin, parent, '数据');
            if (isLayout(origin)) {
                //如果是布局组件,则直接删除
                const index = this.list.findIndex(
                    (item) => item.id === origin.id,
                );
                this.list.splice(index, 1);
            } else {
                //如果不是布局组件，则先判断是不是再布局内部，如果不是，则直接删除就可以，如果是，则要在布局内部删除

                if (parent) {
                    /**
                     * 先判断是否是表格里面的组件，后续此处要重写。
                     * */
                    if (inTable())
                        parent.columns.map((column, index) => {
                            const colIndex = column.list.findIndex(
                                (item) => item.id === origin.id,
                            );
                            if (colIndex > -1) {
                                column.list.splice(colIndex, 1);
                            }
                        });
                } else {
                    if (this.list.length <= 1) {
                        this.$confirm(
                            "确认删除组件？删除该组件后，表单无法保存",
                            "提示",
                            {
                                confirmButtonText: "确定",
                                cancelButtonText: "取消",
                            },
                        )
                            .then(() => {
                                const index = this.list.findIndex(
                                    (item) => item.id === origin.id,
                                );
                                this.list.splice(index, 1);
                                this.$emit("deletedItems", origin)
                                this.$emit("throwOutList", this.list);
                            })
                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "已取消删除",
                                });
                            });
                    } else {
                        const index = this.list.findIndex(
                            (item) => item.id === origin.id,
                        );
                        this.list.splice(index, 1);
                    }
                }
            }
            this.$emit("throwOutList", this.list);
        },
        throwOutList(val) {
            this.$emit("throwOutList", val);
        },
        /**
         * 处理套件摘要
         * @param {*} origin 当前对象
         */
        handlerKitAbstract(origin){
            console.log(origin);
            console.log(this.list);
            let currentKitIndByList = this.list.findIndex(item => item.key === origin[0].kitKey);
            this.$set(this.list[currentKitIndByList], "abstractList", origin);
            this.throwOutList(this.list);
        },

        // 修改审批问题
        changeDayStudentComment(e){
            console.log(e)
        },
    },
    watch: {
        activeItem: {
            handler(val) {
                this.throwOutList(this.list);
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/designer.scss";

.preview-area {
    height: 100%;
    border-radius: 24px;

    .terminal {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        border-radius: 24px 24px 0 0;
        .mobile-img {
            width: 13px;
            padding: 0 13px;
            cursor: pointer;
        }

        .pc-img {
            width: 20px;
            padding: 0 13px;
            cursor: pointer;
        }
    }

    .pc-status {
        background-color: #f7f7f7;
    }

    .mobile-status {
        background-color: #f7f7f7;
    }

    .area-wrapper {
        height: calc(100% - 96px);
        overflow-y: auto;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 40px 18px;
        border-radius: 8px;

        &::after {
            clear: both;
            content: '';
            display: block;
            height: 0;
            line-height: 0;
            overflow: hidden;
        }

        .center-board-row {
            .drawing-board {
                height: 682px;
                margin: 0 auto;
                box-sizing: border-box;
                padding: 40px 18px;
            }
        }
    }

    .pc-area {
        margin: 0px 10px;
    }

    .mobile-area {
        border: 8px solid #f0f0f0;
        border-radius: 48px;
    }
}

.pc-terminal {
    width: 750px;
    background-color: #f7f7f7;
}

.mobile-terminal {
    width: 380px;
}

.page-board .el-form /deep/ .el-form-item__content {
    display: flex;
    // align-items: center;
}

.page-board .el-form /deep/ .el-form-item__content .delete-item {
    width: 5%;
    margin-top: 1px;
    margin-left: -29px;
    z-index: 3033;
    cursor: pointer;
}

.page-board .el-form ::v-deep .el-form-item .el-radio {
    line-height: 32px;
}

.page-board .el-form ::v-deep .el-form-item .el-select {
    width: 100%;
}
</style>
